import { Link } from "gatsby"
import React from "react"
import { Row, Col, Button, Card, Media, Table, Modal } from 'react-bootstrap'

import Markdown from "react-markdown"

import './../assets/css/ten.css'

const wlwData = [
    {
        title: '海量驱动覆盖更广',
        url: require('./../images/bar.png'),
        desc: '秒级接入90%以上的驱动类型，包括通用型驱动、厂商驱动、行业驱动、网关驱动等。'
    },
    {
        title: '沉浸式三维可视化',
        url: require('./../images/bar.png'),
        desc: '一套平台涵盖了图表组件、工艺流程图、GIS组件、视频组件、三维展示等可视化能力。'
    },
    {
        title: '无缝对接数字孪生',
        url: require('./../images/bar.png'),
        desc: '为数字工厂、车间、智慧楼宇、智慧城市等相关领域搭建物联场景的空间底座。'
    },
    {
        title: '丰富项目案例模板',
        url: require('./../images/bar.png'),
        desc: '40+应用场景案例任选，开箱即用，轻松搞定用户需求，大幅节省项目交付成本。'
    },
    {
        title: '率先接入AI应用',
        url: require('./../images/bar.png'),
        desc: 'AI驱动平台应用，数据点自动生成、修改图表自动完成、行业菜单自动搭建等功能。'
    },
    {
        title: '丰富算法模型库',
        url: require('./../images/bar.png'),
        desc: '平台积累了丰富的行业算法模型库，产品生态共建行业智能。'
    }
]


const Hexagon = props => {

    return (
        <Row xs={1} lg={3} md={2} className="hexagon-al-row">
            {
                wlwData.map(item => {
                    return (
                        <Col>
                            <Card className="hexagon-card-box">
                                <div className="hexagon-img"><img /> </div>
                                <div className="hexagon-title">{item.title}</div>
                                <div className="hexagon-desc">{item.desc}</div>
                            </Card>
                        </Col>
                    )
                })
            }
        </Row>
    )

    return (
        <Row xs={1} md={2} lg={4} className="row-common">
            {
                wlwData.map((item, index) => {
                    return (
                        <Col key={index}>
                            <Card className="common-card">
                                <div className="hexagon-box">
                                    <div class="hexagon"><span></span></div>
                                    <Card.Img className={item.class} variant="top" src={item.url} alt={item.title} />
                                </div>
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <div className="card-text">
                                        <Markdown source={item.desc} escapeHtml={false} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                })
            }
            {/* <Col>
            <Card className="common-card">
                <div className="hexagon-box">
                <div class="hexagon"><span></span></div>
                    <Card.Img variant="top" src={require('./../images/bar.png')} alt="节约成本" />
                </div>
                <Card.Body>
                    <Card.Title>节约成本</Card.Title>
                    <div className="card-text">
                        <div class="card-text">平台<span class="card-text-active">自带丰富的项目、应用模板，</span>能有效帮助用户降低设计开发及实施成本，<span class="card-text-active">节约开支</span></div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        <Col key={index}>
            <Card className="common-card">
            <div className="hexagon-box">
            <div class="hexagon"><span></span></div>
                <Card.Img className={item.class} variant="top" src={item.url} alt={item.title} />
            </div>
            <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <div className="card-text">
                    <Markdown source={item.desc} escapeHtml={false} />
                </div>
            </Card.Body>
            </Card>
        </Col>
        <Col key={index}>
            <Card className="common-card">
            <div className="hexagon-box">
            <div class="hexagon"><span></span></div>
                <Card.Img className={item.class} variant="top" src={item.url} alt={item.title} />
            </div>
            <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <div className="card-text">
                    <Markdown source={item.desc} escapeHtml={false} />
                </div>
            </Card.Body>
            </Card>
        </Col>
        <Col key={index}>
            <Card className="common-card">
            <div className="hexagon-box">
            <div class="hexagon"><span></span></div>
                <Card.Img className={item.class} variant="top" src={item.url} alt={item.title} />
            </div>
            <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <div className="card-text">
                    <Markdown source={item.desc} escapeHtml={false} />
                </div>
            </Card.Body>
            </Card>
        </Col> */}
        </Row>
    )
}

export default Hexagon
