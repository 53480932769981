import React, { useRef } from "react"
import { Link, navigate } from "gatsby"
import { Row, Col, Button, Card, Media, Modal } from 'react-bootstrap'

import { bdstatistics, apiURL, mqStatistics, check } from '../components/census'
import { useStaticQuery, graphql } from "gatsby"

import './../components/media.css'
import './../assets/css/index.css'
import './../components/release.css'
import './../assets/css/aritcle.css'

// 引入此路径，才不会打包失败
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import './../assets/css/navigation.min.css'
import './../assets/css/pagination.min.css'
import './../assets/css/scrollbar.min.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const MoreApplicationScenarios = props => {
  const [ activeIndex, setActiveIndex ] = React.useState(1)
  const [swipers, setSwipers] = React.useState(null)
  const [ row, setRow ]  = React.useState(false)
  const [ initPerView, setPerView ] = React.useState(4)
  const swiperRef = useRef()

  const applicationScenarios = useStaticQuery(graphql`
    {
      allStrapiApplicationScenario (sort: {fields: updated_at, order: DESC}, filter: {homePage: {eq: true}}){
        nodes {
          bannerTitle
          created_at
          homePage
          id
          introduction
          order
          updated_at
          strapiId
          sort
          published_at
          HomeTabImage {
            childImageSharp {
              fixed(width: 1600, height: 800) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }
  `)
  const applicationScenariosData = applicationScenarios?.allStrapiApplicationScenario?.nodes
  const jsonData = applicationScenariosData.filter(item => item?.homePage).sort((a, b) => a.order - b.order)
  const data = jsonData.map(item => {
    const bannerTitle = item.bannerTitle && item.bannerTitle.substring(0, item.bannerTitle.indexOf('案例'))
    return { ...item, bannerTitle }
  })
   //     setData([ ...jsonData.map(item => {
    //       const bannerTitle = item.bannerTitle && item.bannerTitle.substring(0, item.bannerTitle.indexOf('案例'))
    //       return { ...item, bannerTitle }
    //     }) ])
  console.log(data, '首页的更多案例显示data')

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
    let width = document.body.clientWidth

    if(!check()) {
      setPerView(1)
    } else {
      if(width <= 400) {
        setPerView(1)
      } else if(width <= 768 && width > 400) {
        setPerView(2)
      } else if (width <= 1100 && width > 768 ) {
        setPerView(3)
      } else if (width <= 1200 && width > 1100 ) {
        setPerView(4)
      } else if (width <= 1440 && width > 1200 ) {
        setPerView(3)
      } else if (width <= 1920 && width > 1440) {
        setPerView(4)
      } else {
        setPerView(5)
      }
    } 

    if (typeof window !== "undefined") {
    
      window.addEventListener('resize', (event) => {
        let width = document.body.clientWidth
        if(width <=1024) {
          setRow(true)
        }

        if(width <= 400) {
          setPerView(1)
        } else if(width <= 768 && width > 400) {
          setPerView(2)
        } else if (width <= 1100 && width > 768 ) {
          setPerView(3)
        } else if (width <= 1200 && width > 1100 ) {
          setPerView(4)
        } else if (width <= 1440 && width > 1200 ) {
          setPerView(3)
        } else if (width <= 1920 && width > 1440) {
          setPerView(4)
        } else {
          setPerView(5)
        }
      })
    }

  }, [ ])

  return  <div className="engineTopBox" style={{ height: !check() ? '25.6875rem' : '48.6875rem' }}>
    <div className="engineBox">
      <div className="engineTop">
        <Row>
          <Col md={10} lg={10}>
            <Row>
            {
              data.map((item, index) => {
                return <Col><div className="gap-sm" onClick={() => swipers.slideToLoop(index, 1000, false)}>
                  <div className={activeIndex == (index + 1) ? "font-body font-body-active" : "font-body"}>{item?.bannerTitle}</div>
                </div></Col>
              })
            }
            { row ?  <Col>
              <Link className="gap-sm" to="/articles">
                <div className="font-body" >
                  更多案例
                  <img className="font-svg" src={require('./../images/arrowright.svg')} />
                </div>
              </Link>
            </Col> : false }
            </Row>
          </Col>
          <div className="line-engine" style={{ height: '80%', borderWidth: 1, borderRightColor: 'rgba(22,18,46,.3)' }}></div>
          { row ? null : <Col className="viewMore-col" md={2} lg={2}>
            <Link className="gap-sm viewMore" to="/articles">
              <div className="font-body" >
                更多案例
                <img className="font-svg" src={require('./../images/arrowright.svg')} />
              </div>
            </Link>
          </Col> }
        </Row>
      </div>
      <div className="engineRight" style={{ width: '100%' }}>
        <Swiper
          loop={true}
          // autoplay={{
          //   delay: 5000,
          //   stopOnLastSlide: false,
          //   disableOnInteraction: false
          // }}
          effect='fade'
          lazy={{ loadPrevNext: true }}
          ref={swiperRef}
          modules={[Navigation, Autoplay, Pagination]}
          slidesPerView={1}
          //  navigation
          //  pagination={{ clickable: true }}
          onSwiper={(swiper) => setSwipers(swiper)}
          onSlideChange={(swiper) => { if (swiper.activeIndex == data?.length + 1) { setActiveIndex(1) } else { setActiveIndex(swiper.activeIndex) } }}
          className="new_custom engine-swiper partner-swiper"
        >
          {
            data.map((item, index) => {
          
              if(item?.bannerTitle == '智慧工厂') {
                return (<SwiperSlide>
                  <div className="link-img" key={item.bannerTitle} style={{ position: 'relative', width: '100%', height: !check() ? '20.6875rem' : '48.6875rem' }}>
                    <Link to={`/strapi/uploads/AIRIOT_0920_af8b18bbc0.pdf`} target="_blank"><img src={`${item?.HomeTabImage?.childImageSharp?.fixed?.src}`} style={{ width: '100%', height: '100%' }} /></Link>
                    <Link to={`/strapi/uploads/AIRIOT_0920_af8b18bbc0.pdf`} target="_blank">
                      <Button style={{ position: 'absolute', top: '1rem', right: '2rem' }}>查看详情</Button>
                    </Link> 
                  </div>
                </SwiperSlide>)
              }
              return (
                <SwiperSlide>
                  <div className="link-img" key={item.bannerTitle} style={{ position: 'relative', width: '100%', height: !check() ? '20.6875rem' : '48.6875rem' }}>
                    <Link to={`/articlesDetail/${item?.strapiId}`}><img src={`${item?.HomeTabImage?.childImageSharp?.fixed?.src}`} style={{ width: '100%', height: '100%' }} /></Link>
                    <Link to={`/articlesDetail/${item?.strapiId}`}>
                      <Button style={{ position: 'absolute', top: '1rem', right: '2rem' }}>查看详情</Button>
                    </Link> 
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>
    </div>
  </div>

}

export default MoreApplicationScenarios